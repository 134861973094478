import { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { object, string } from 'prop-types';
import sprites from './sprite.svg';
import * as styles from './index.module.scss';
import { ResourceMainImage } from '../../components/resource-main-image';
import { Wrap } from '../../components/sections-wrap';
import { ChatButton } from '../../components/common/chat-button';

function Icon({ id, className }) {
  return (
    <svg className={className}>
      <use
        xlinkHref={`${sprites}#${id}`}
      />
    </svg>
  );
}

function ResourceCenter({ data }) {
  const [value, setValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `/search?value=${value}`;
  };

  return (
    <>
      <ResourceMainImage title="Resource Center" />
      {/* change */}

      <div className={styles.sectionCardsContainer}>
        <Wrap>
          <div className={styles.cardsContainer}>
            <div className={styles.card}>
              <h3 className={styles.title}>
                <Icon id="magnifying-glass" className={styles.svg} />
                Search
              </h3>
              <div className={styles.description}>
                <p>Search all resources including:</p>
                <ul>
                  <li>Legal Guides</li>
                  <li>Results</li>
                  <li>FAQs</li>
                  <li>Blogs</li>
                  <li>More...</li>
                </ul>
              </div>
              <div className={styles.actions}>
                <div className={styles.formContainer}>
                  <form className={styles.form} onSubmit={handleSubmit}>
                    <label htmlFor="search">
                      <input
                        type="search"
                        className={styles.input}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Search…"
                        id="search"
                      />
                    </label>
                    <button type="submit" className={styles.button}>
                      <Icon id="magnifying-glass" className={styles.svgSmall} />
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className={styles.cardBrowse}>
              <div>
                <h3 className={styles.title}>
                  <Icon id="document" className={styles.svg} />
                  Browse
                </h3>
                <div className={styles.description}>
                  <p>Explore topics related to your legal issue.</p>
                </div>
                <div className={styles.topicButtonContainer}>
                  <Link to="/resource-center/browse-topics/" className={styles.button}>Browse by Topic</Link>
                </div>
              </div>
              <div>
                {' '}
                <div className={styles.description}>
                  <p>Discover which areas of law our firm practices.</p>
                </div>
                <div className={styles.actions}>
                  <Link to="/resource-center/browse-resources/" className={styles.button}>Browse by Legal Area</Link>
                </div>
              </div>
            </div>

            <div className={styles.card}>
              <h3 className={styles.title}>
                <Icon id="email" className={styles.svg} />
                Ask
              </h3>
              <div className={styles.description}>
                <p>Can't find what you're looking for?</p>
              </div>
              <div className={styles.actions}>
                <Link to="/questions/" className={styles.button}>Ask a Question</Link>
              </div>
              <h3 className={styles.title}>
                <Icon id="start-a-chat" className={styles.svg} />
                Chat
              </h3>
              <div className={styles.description}>
                <p>Need to talk directly with a representative?</p>
              </div>
              <div className={styles.actions}>
                <ChatButton className={styles.button} text="Live Chat" />
              </div>
            </div>
          </div>
        </Wrap>

      </div>
    </>
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/resource-center/"}) {
      ...SeoPage
      title
    }
  }
`;

Icon.propTypes = {
  id: string.isRequired,
  className: string,
};

Icon.defaultProps = {
  className: '',
};

ResourceCenter.propTypes = {
  data: object,
};

ResourceCenter.defaultProps = {
  data: {},
};

export default ResourceCenter;
export { Head } from '../../components/seo/seo';
