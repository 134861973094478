// extracted by mini-css-extract-plugin
export var actions = "index-module--actions--e7e64";
export var button = "index-module--button--36f3a";
export var card = "index-module--card--e377b";
export var cardBrowse = "index-module--card-browse--5731c";
export var cardsContainer = "index-module--cards-container--ff6c8";
export var description = "index-module--description--47061";
export var form = "index-module--form--684e4";
export var formContainer = "index-module--form-container--2c7f5";
export var input = "index-module--input--58929";
export var sectionCardsContainer = "index-module--section-cards-container--90161";
export var svg = "index-module--svg--82752";
export var svgSmall = "index-module--svg-small--9f550";
export var text = "index-module--text--30dd7";
export var title = "index-module--title--5f6ab";
export var topicButtonContainer = "index-module--topic-button-container--6ee30";